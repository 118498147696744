import React from 'react';
import { axios } from "../../utilities/axios";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthScene from "./AuthScene/AuthScene";

class LoginScene extends React.Component {

  state = {loading: false, email: '', password: '', error: null};

  render() {
    // Allow time for cookie credentials to be validated before showing UI
    if (this.props.loadingFromCookies) {
      return <div className="LoginScene"/>;
    }

    const {loading, email, password, error} = this.state;

    return <AuthScene>
      {error ? <p className="error">{error}</p> : null}

      <form onSubmit={this.onSubmit.bind(this)} className={loading ? 'loading' : ''}>
        <input name="email" type="email" placeholder="Email Address" value={email} onChange={this.onChange.bind(this)}/>
        <input name="password" type="password" placeholder="Password" value={password} onChange={this.onChange.bind(this)}/>
        <button className={loading ? 'loading' : ''} onClick={this.onSubmit.bind(this)}>{loading ? 'Loading...' : 'Login'}</button>
        <br/>
        <Link to="/forgot-password">Forgotten your password?</Link>
      </form>
    </AuthScene>;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({loading: true});

    // const prefix = "http://localhost:5000"
    const prefix = "";

    axios.post(`${prefix}/api/business/v1/authentication/login`, {email: this.state.email, password: this.state.password})
      .then(res => this.props.setUser(res.data))
      .catch(err => this.setState({loading: false, error: 'Incorrect Email / Password'}));
  }

  onChange(i) {
    this.setState({[i.target.name]: i.target.value});
  }

}

const mapStateToProps = store => {
  return {
    loadingFromCookies: store.auth.loadingFromCookies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: payload => dispatch({type: 'AUTH_SET_USER', payload}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScene);
