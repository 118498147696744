import { axios } from '../utilities/axios';
import Cookie from 'react-cookies';
import store from './index';

export default (state = {}, action) => {
  switch (action.type) {
    case "AUTH_SET_USER": {
      const user = action.payload;
      Cookie.save('email', user.email, {path: '/'});
      Cookie.save('auth_token', user.auth_token, {path: '/'});
      return {user};
    }
    case "AUTH_COOKIE_FAILED":
      return {loadingFromCookies: false};
    case "AUTH_COOKIE_LOADING":
      return {loadingFromCookies: true};
    case "AUTH_LOGOUT":
      Cookie.remove('email');
      Cookie.save('auth_token');
      return {};
    default :
      return state;
  }
}

export const loadAuthFromCookies = () => {
  const email = Cookie.load('email');
  const token = Cookie.load('auth_token');
  if (!email || !token) {
    return;
  }

  store.dispatch({type: 'AUTH_COOKIE_LOADING'});
  const auth = {username: email, password: token};

  axios.get('/api/business/v1/authentication/whoami', {auth})
    .then(res => store.dispatch({type: 'AUTH_SET_USER', payload: res.data}))
    .catch(err => store.dispatch({type: 'AUTH_COOKIE_FAILED'}));
};

