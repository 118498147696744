import './EnvRibbon.scss';
import React from "react";

class EnvRibbon extends React.Component {
  render() {
    return <React.Fragment>
      {process.env.NODE_ENV === 'development' && <span className="env-alert-ribbon text-center" title="Development">Development</span>}
      {process.env.REACT_APP_STAGING === 'true' && <span className="env-alert-ribbon text-center" title="Staging">Staging</span>}
    </React.Fragment>;
  }

}

export default EnvRibbon;
