import React from 'react';
import { axios } from "../../utilities/axios";
import { connect } from 'react-redux';
import { cacheMenu, createMenuCategory, deleteMenu, restoreMenu, restoreMenuItem, updateMenu, validateMenu } from '../../store/menus';
import './MenuScene.scss';
import PageLayout from '../../components/PageLayout';
import Header from './components/Header';
import Category from './components/Category';

class MenuScene extends React.Component {
  render() {
    const {menu} = this.props;
    if (!menu) {
      return <PageLayout title="Error: Menu Not Found" {...this.props} />;
    }

    const categories = [...menu.categories].sort((a, b) => {
      let x = a.sorting_order;
      let y = b.sorting_order;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    return <PageLayout title="MenuScene" {...this.props}>
      <Header
        key={menu.uuid}
        menu={menu}
        user={this.props.user}
        updateMenu={this.props.updateMenu}
        addCategory={this.props.createMenuCategory}
        deleteMenu={this.deleteMenu}
        save={this.onSave.bind(this)}
      />

      <div className="categories-container">
        {categories.map((c) => {
          return <Category
            key={c.uuid}
            menu={menu}
            category={c}
            branches={this.props.user.business.branches}
            saveMenu={this.onSave.bind(this)}
            restoreMenu={this.onRestore.bind(this)}
            restoreMenuItem={this.onRestoreItem.bind(this)}
          />;
        })}
      </div>
    </PageLayout>;
  }

  deleteMenu = () => {
    const {menu, user} = this.props;
    const auth = {username: user.email, password: user.auth_token};
    axios.delete(`/api/business/v1/menus/${menu.uuid}`, {auth})
      .then(res => this.props.deleteMenu({uuid: menu.uuid}))
      .catch(err => alert("An error occurred deleting this menu" + err));
  };

  onRestore() {
    const {menu} = this.props;
    if (window.confirm("Are you sure you want to cancel without saving your changes?")) {
      this.props.restoreMenu(menu);
      return true;
    }
    return false;
  }

  onRestoreItem(args) {
    if (window.confirm("Are you sure you want to cancel without saving your changes?")) {
      this.props.restoreMenuItem(args);
      return true;
    }
    return false;
  }

  onSave() {
    const {menu, user} = this.props;
    const errors = validateMenu(menu);
    if (errors.length > 0) {
      alert(`Please correct invalid data (${errors.length} Errors): \n\n${errors.join('\n')}`);
      return false;
    }

    const params = {
      uuid: menu.uuid,
      name: menu.name,
      description: menu.description,
      visible: menu.visible,
      categories_attributes: menu.categories.map(c => ({
        name: c.name,
        description: c.description,
        sorting_order: c.sorting_order,
        items_attributes: c.items,
      })),
    };

    const auth = {username: user.email, password: user.auth_token};
    axios.post('/api/business/v1/menus/', {menu: params}, {auth})
      .then(res => {
        this.props.cacheMenu(menu);
        return this.props.updateMenu({uuid: menu.uuid, edited: false, new: false});
      })
      .catch(err => alert("An error occurred updating the menu's visibility"));
    return true;
  }
}

const mapStateToProps = (state, props) => ({
  menu: state.menus[props.match.params.uuid],
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  restoreMenu: args => dispatch(restoreMenu(args)),
  cacheMenu: args => dispatch(cacheMenu(args)),
  restoreMenuItem: args => dispatch(restoreMenuItem(args)),
  updateMenu: args => dispatch(updateMenu(args)),
  createMenuCategory: uuid => dispatch(createMenuCategory(uuid)),
  deleteMenu: uuid => dispatch(deleteMenu(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuScene);