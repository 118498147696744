import { configureStore } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import authentication, { loadAuthFromCookies } from './authentication';
import menus from './menus';

export default configureStore({
  reducer: combineReducers({
    auth: authentication,
    menus: menus,
  }),
});

loadAuthFromCookies();