import React from 'react';
import Logo from "../../../assets/images/logo.png";
import './AuthScene.scss';
import EnvRibbon from "../../../components/StagingRibbon";
import { Link } from "react-router-dom";

export default class extends React.Component {

  render() {
    return <div className="AuthScene">
      <div className="inner">
        <Link to="/">
          <img src={Logo} alt="Logo"/>
        </Link>
        <EnvRibbon/>
        <h1>
          Business Login
          {
            this.props.title && <React.Fragment>{" - " + this.props.title}</React.Fragment>
          }
        </h1>

        {this.props.children}
      </div>
    </div>;
  }

}