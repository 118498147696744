import React from 'react';
import uuidv4 from 'uuid/v4';
import { createMenu, reorderMenus } from '../../store/menus';
import { connect } from 'react-redux';
import AddIcon from '../../assets/images/add.png';
import Logo from '../../assets/images/logo.png';
import Invisible from '../../assets/images/invisible.png';
import InvisibleWhite from '../../assets/images/invisible-white.png';
import { Link, NavLink } from 'react-router-dom';
import UpArrow from '../../assets/images/up-arrow.png';
import DownArrow from '../../assets/images/down-arrow.png';
import { axios } from "../../utilities/axios";
import './PageLayout.scss';
import EnvRibbon from "../StagingRibbon";

class PageLayout extends React.Component {
  render() {
    return <div className="PageLayout">
      <div className="sidebar">
        <Link to="/">
          <img src={Logo} alt="Logo"/>
        </Link>
        <EnvRibbon/>
        <div className="nav">
          <div className="nav-section">
            <h4>Your Menus</h4>
            {this.props.menus.map(this.renderMenuLink.bind(this))}
            <button className="new-menu" onClick={this.props.createMenu}>New Menu<img className="logo" src={AddIcon} alt="New Menu"/></button>
          </div>

          <div className="nav-section">
            <h4>Other</h4>
            <a href="mailto:support@dietarymenu.com">Support</a>
            <button onClick={this.props.logout}>Logout</button>
          </div>
        </div>
      </div>

      <div className={`content ${this.props.title}`}>
        {this.props.children}
      </div>
    </div>;
  }

  reorderMenus = (params) => {
    const {user, menus} = this.props;
    const auth = {username: user.email, password: user.auth_token};
    const {uuid, sorting_order} = params;

    const reordered = menus.sort((a, b) => {
      if (a.uuid === uuid) {
        return sorting_order - b.sorting_order;
      } else if (b.uuid === uuid) {
        return a.sorting_order - sorting_order;
      } else {
        return a.sorting_order - b.sorting_order;
      }
    });

    axios.post('/api/business/v1/menus/reorder', {menu_uuids: reordered.map(x => x.uuid)}, {auth})
      .then(res => this.props.reorderMenus({menus: res.data}))
      .catch(err => alert("An error occurred updating the menus order" + err));
  };

  renderMenuLink(m) {
    const active = (this.props.match ? this.props.match.params.uuid : "INVALID") === m.uuid;
    const icon = active ? InvisibleWhite : Invisible;

    let right = null;
    if (m.edited) {
      right = <p><span role={"img"} aria-label={"Menu has been modified"}>❗</span>️></p>;
    } else if (!m.visible) {
      right = <img className="logo" src={icon} alt="Menu Hidden"/>;
    } else {
      right = (
        <div className="arrows">
          <img alt={"Sort Up"} src={UpArrow} onClick={() => this.reorderMenus({
            uuid: m.uuid, sorting_order: m.sorting_order - 2,
          })}/>
          <img alt={"Sort Down"} src={DownArrow} onClick={() => this.reorderMenus({
            uuid: m.uuid, sorting_order: m.sorting_order + 2,
          })}/>
        </div>
      );
    }

    return (
      <NavLink className="menu-nav" key={m.uuid} to={`/menus/${m.uuid}`}>
        {m.name || "-"}
        {right}
      </NavLink>
    );
  }
}

const mapStateToProps = state => ({
  menus: Object.values(state.menus).sort((a, b) => a.sorting_order - b.sorting_order),
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  createMenu: () => {
    const uuid = uuidv4();
    dispatch(createMenu({uuid}));
    props.history.push('/menus/' + uuid);
  },
  reorderMenus: (menus) => dispatch(reorderMenus(menus)),
  logout: () => dispatch({type: 'AUTH_LOGOUT'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
