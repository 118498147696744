import React from 'react';
import { Allergens, FreeFroms } from '../../../../../../store/menus';
import './AllergensPopup.scss';
import SelectionMenu from "../SelectionMenu";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faExclamation, faM, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export class AllergensPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lastClicked: null,
    };
  }

  render() {
    const {
      item,
      toggle,
      deleteItem,
      onHiddenBranchesUpdate,
      menu,
      saveMenu,
      restoreMenu,
      restoreMenuItem,
    } = this.props;

    const {name, notes} = item;

    const onCancel = () => {
      if (restoreMenuItem({menuUuid: menu.uuid, itemUuid: item.uuid})) {
        toggle();
      }
    };
    const onClickAway = () => this.setState({lastClicked: null});

    const onSave = () => {
      // if (saveMenu(menu)) {
      toggle();
      // }
    };

    return <React.Fragment>
      <div className="AllergensPopup" onClick={onClickAway}>
        <div className="top-bar">
          <p className="title">
            {name}
            <FontAwesomeIcon className={"deleteButton"} icon={faTrashAlt} onClick={deleteItem}/>
          </p>

          <div className="actions">
            {
              menu.edited ? <>
                <button className="btn white" onClick={onCancel.bind(this)}>Cancel changes</button>
                <button className="btn blue" onClick={onSave.bind(this)}>Save & Close</button>
              </> : <>
                <button className="btn white" onClick={toggle}>Close</button>
              </>
            }
          </div>
        </div>

        <div className="row">
          <div className="col col-sm-12 col-md-12 col-lg-8">
            <Card>
              <Card.Header>Suitability & Allergens</Card.Header>
              <Card.Body>
                <div className="allergens-list">
                  <p className="section-title">Suitable for</p>
                  <div className="allergens-container">

                    <div className="allergen-row d-grid grid-cols-5">
                      <div className="blank-cell">&nbsp;</div>
                      <div className="option-cell fw-bold">No</div>
                      <div className={"option-cell fw-bold"}>Ask to modify</div>
                      <div className="option-cell fw-bold">Yes</div>
                      <div className="blank-cell">&nbsp;</div>
                    </div>
                    {Object.keys(FreeFroms).map(a => {
                      const title = FreeFroms[a];
                      const value = this.props.item[a];

                      const onChange = (newVal) => this.props.update({
                        uuid: this.props.item.uuid,
                        [a]: newVal,
                      });

                      const onClick = (val) => {
                        this.setState({lastClicked: a});
                        onChange(val);
                      };

                      let colours = {
                        "no": "red",
                        "modifiable": "blue",
                        "yes": "green",
                      };

                      let valueActive = (staticVal, value) => {
                        if (staticVal === value) {
                          return `active ${colours[value]}`;
                        } else {
                          return `inactive ${colours[value]}`;
                        }
                      };

                      return <React.Fragment key={a}>
                        <div className={`allergen-row ${colours[value]} ${this.state.lastClicked === a ? 'last-clicked' : ''}  d-grid grid-cols-5`} onClick={onClickAway}>
                          <p className="label" onClick={() => onClickAway()}>{title}</p>
                          <div className={`option-cell red ${value === "no" ? "active" : 'inactive'}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("no");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faTimes} transform={'shrink-2'}/>
                            </span>
                          </div>
                          <div className={`option-cell blue ${value === "modifiable" ? "active" : 'inactive'}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("modifiable");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faM} transform={'shrink-4'}/>
                            </span>
                          </div>
                          <div className={`option-cell green ${value === "yes" ? "active" : 'inactive'}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("yes");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faCheck} transform={'shrink-2'}/>
                            </span>
                          </div>
                          <div className="blank-cell">&nbsp;</div>
                        </div>
                      </React.Fragment>;
                    })}
                  </div>
                </div>
                <div className="divider horizontal my-4"></div>
                <div className="allergens-list">
                  <p className="section-title">Allergens</p>

                  <div className="allergens-container">

                    <div className="allergen-header-row d-grid grid-cols-5">
                      <div className={"blank-cell"}>&nbsp;</div>
                      <div className={"option-cell fw-bold"}>Contains</div>
                      <div className={"option-cell fw-bold"}>Cross contamination</div>
                      <div className={"option-cell fw-bold"}>Ask to modify</div>
                      <div className={"option-cell fw-bold"}>Free from</div>
                    </div>

                    {Object.keys(Allergens).map(a => {
                      const title = Allergens[a];
                      const value = this.props.item[a];

                      const onChange = newValue => this.props.update({
                        uuid: this.props.item.uuid,
                        [a]: newValue,
                      });

                      const onClick = (val) => {
                        this.setState({lastClicked: a});
                        onChange(val);
                      };

                      let colours = {
                        "contains": "red",
                        "cross_contamination": "orange",
                        "modifiable": "blue",
                        "free_from": "green",
                      };

                      let valueActive = (staticVal, value) => {
                        if (staticVal === value) {
                          return `active`;
                        } else {
                          return `inactive`;
                        }
                      };

                      return <React.Fragment key={a}>
                        <div className={`allergen-row ${colours[value]} ${this.state.lastClicked === a ? 'last-clicked' : ''}  d-grid grid-cols-5`} onClick={onClickAway}>
                          <p className="label" onClick={() => onClickAway()}>{title}</p>
                          <div className={`option-cell red ${valueActive("contains", value)}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("contains");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faTimes} transform={'shrink-2'}/>
                            </span>
                          </div>
                          <div className={`option-cell orange ${valueActive("cross_contamination", value)}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("cross_contamination");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faExclamation} transform={'shrink-2'}/>
                            </span>
                          </div>
                          <div className={`option-cell blue ${valueActive("modifiable", value)}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("modifiable");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faM} transform={'shrink-4'}/>
                            </span>
                          </div>
                          <div className={`option-cell green ${valueActive("free_from", value)}`} onClick={(e) => {
                            e.stopPropagation();
                            onClick("free_from");
                          }}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size={"lg"}/>
                              <FontAwesomeIcon icon={faCheck} transform={'shrink-2'}/>
                            </span>
                          </div>
                        </div>

                      </React.Fragment>;
                    })}
                  </div>
                </div>
              </Card.Body>
            </Card>

          </div>
          <div className="col col-sm-12 col-md-12 col-lg-4 mt-md-4 mt-lg-0">
            <Card>
              <Card.Header>Location availability</Card.Header>
              <Card.Body>
                <SelectionMenu
                  options={this.props.branches}
                  hidden={item.hidden_branches}
                  onItemChange={onHiddenBranchesUpdate}
                />
              </Card.Body>
            </Card>

            <Card className={"mt-2"}>
              <Card.Header>Notes</Card.Header>
              <Card.Body>
                <input
                  type="text"
                  className="notes"
                  placeholder="Add a note here"
                  value={notes || ""}
                  onChange={this.onNotesChange.bind(this)}
                />
              </Card.Body>
            </Card>

          </div>
        </div>
      </div>
    </React.Fragment>;
  }

  onNotesChange(i) {
    this.props.update({
      uuid: this.props.item.uuid,
      notes: i.target.value,
    });
  }
}

export default AllergensPopup;