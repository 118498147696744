import React from 'react';
import { axios } from "../../../../utilities/axios";
import './Header.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default class Header extends React.Component {

  componentDidMount() {
    const {menu} = this.props;
    if (menu && !menu.name) {
      this.nameInput.focus();
    }
  }

  render() {
    const {menu} = this.props;

    let right;
    if (menu.new) {
      right = this.renderCreate();
    } else if (menu.edited) {
      right = this.renderSave();
    } else {
      right = this.renderTogglePrivacy();
    }

    return <div className="Header">
      <input type="text" value={menu.name || ""} placeholder="Menu Name" onChange={this.updateName.bind(this)} ref={i => {
        this.nameInput = i;
      }}/>
      <button className="btn blue" onClick={this.addCategory.bind(this)}>Add Section</button>
      <FontAwesomeIcon className={"deleteButton"} icon={faTrashAlt} onClick={this.deleteMenu}/>
      {right}
    </div>;
  }

  renderSave() {
    return <div className="save">
      <p>Changes have been made to this menu. <br/>Press 'Publish Changes' for them to take effect.</p>
      <button className="btn red" onClick={this.props.save}>Publish Changes</button>
    </div>;
  }

  renderCreate() {
    return <div className="save">
      <p>This is a new menu, press 'Create Menu' to save it.<br/>The menu will remain private until changed.</p>
      <button className="btn red" onClick={this.props.save}>Create Menu</button>
    </div>;
  }

  renderTogglePrivacy() {
    const {visible} = this.props.menu;

    return <div className="private">
      <button
        onClick={this.togglePrivate.bind(this)}
        className={`btn ${visible ? 'white' : 'blue'}`}>
        Private
      </button>

      <button
        onClick={this.togglePrivate.bind(this)}
        className={`btn ${visible ? 'blue' : 'white'}`}>
        Public
      </button>
    </div>;
  }

  togglePrivate() {
    const {visible, uuid} = this.props.menu;
    const {email, auth_token} = this.props.user;
    const auth = {username: email, password: auth_token};

    let menu = {uuid, visible: !visible};
    axios.patch('/api/business/v1/menus/' + uuid, {menu}, {auth})
      .then(_res => this.props.updateMenu(menu))
      .catch(_err => alert("An error occurred updating the menu's visibility"));
  }

  updateName(e) {
    const {uuid} = this.props.menu;
    this.props.updateMenu({uuid, name: e.target.value});
  }

  addCategory() {
    const {uuid} = this.props.menu;
    this.props.addCategory({uuid});
  }

  deleteMenu = () => {
    const {uuid} = this.props.menu;
    if (window.confirm("Are you sure you want to delete this entire menu (and its contents)?")) {
      this.props.deleteMenu({uuid});
    }
  };

}