import React from 'react';
import { createMenuItem, deleteMenuCategory, deleteMenuItem, reorderMenuCategory, updateMenuCategory, updateMenuItem } from '../../../../store/menus';
import { connect } from 'react-redux';
import Item from '../Item';
import UpArrow from '../../../../assets/images/up-arrow.png';
import DownArrow from '../../../../assets/images/down-arrow.png';
import './Category.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class Category extends React.Component {
  render() {
    return <div className="Category">
      {this.renderUpper()}
      {this.renderLower()}
    </div>;
  }

  renderUpper() {
    const {uuid, name, sorting_order} = this.props.category;
    return <div className="upper">
      <input type="text" value={name} placeholder="Category Name" onChange={this.onNameChange.bind(this)}/>

      <div className="arrows">
        <img src={UpArrow} alt="Move Category Up" onClick={() => this.props.reorder({uuid, sorting_order: sorting_order - 1})}/>
        <img src={DownArrow} alt="Move Category Down" onClick={() => this.props.reorder({uuid, sorting_order: sorting_order + 1})}/>
      </div>

      <button className="btn blue" onClick={this.onAddItem.bind(this)}>Add Item</button>

      <FontAwesomeIcon className={"deleteButton"} alt="Delete Category" icon={faTrashAlt} onClick={this.onDelete.bind(this)}/>
    </div>;
  }

  onAddItem() {
    this.props.addItem(this.props.category.uuid);
  }

  onDelete() {
    if (window.confirm("Are you sure you want to delete this menu category?")) {
      this.props.delete(this.props.category.uuid);
    }
  }

  onNameChange(i) {
    this.props.update({
      uuid: this.props.category.uuid,
      name: i.target.value,
    });
  }

  renderLower() {
    const menu = this.props.menu;
    return <div className="lower">
      {this.props.category.items.map(i => <Item
        branches={this.props.branches}
        key={i.uuid}
        item={i}
        menu={menu}
        saveMenu={this.props.saveMenu}
        restoreMenu={this.props.restoreMenu}
        restoreMenuItem={this.props.restoreMenuItem}
        update={this.props.updateItem}
        delete={this.props.deleteItem}/>)}
    </div>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  reorder: args => dispatch(reorderMenuCategory(args)),
  update: args => dispatch(updateMenuCategory(args)),
  delete: uuid => dispatch(deleteMenuCategory({uuid})),
  addItem: uuid => dispatch(createMenuItem({uuid})),
  updateItem: args => dispatch(updateMenuItem(args)),
  deleteItem: uuid => dispatch(deleteMenuItem({uuid})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);