import React from 'react';
import AllergensPopup from './components/AllergensPopup';
import DropdownIcon from '../../../../assets/images/dropdown.png';
import './Item.scss';
import { Modal } from "react-bootstrap";

export default class Item extends React.Component {
  state = {popoverOpen: false};

  togglePopover = () => this.setState({popoverOpen: !this.state.popoverOpen});

  render() {
    const {item, branches, menu} = this.props;

    return (
      <div className="Item">
        <div className="header">
          <div className="wrapper">
            <input type="text" value={this.props.item.name || ""} placeholder="Item Name" onChange={this.onNameChange.bind(this)}/>
          </div>
          <img src={DropdownIcon} alt="Expand Item" onClick={this.togglePopover.bind(this)}/>
          <Modal
            show={this.state.popoverOpen}
            onHide={this.togglePopover.bind(this)}
            dialogClassName="modal-extra-wide"
          >
            <Modal.Body>
              <AllergensPopup
                toggle={this.togglePopover.bind(this)}
                item={item}
                menu={menu}
                saveMenu={this.props.saveMenu}
                restoreMenu={this.props.restoreMenu}
                restoreMenuItem={this.props.restoreMenuItem}
                branches={branches}
                deleteItem={this.onDelete.bind(this)}
                update={this.props.update}
                onHiddenBranchesUpdate={this.onHiddenBranchesUpdate.bind(this)}
              />
            </Modal.Body>

          </Modal>
        </div>
      </div>
    );
  }

  onNameChange(i) {
    this.props.update({
      uuid: this.props.item.uuid,
      name: i.target.value,
    });
  }

  onDelete() {
    if (window.confirm("Are you sure you want to delete this item?")) {
      this.props.delete(this.props.item.uuid);
    }
  }

  onHiddenBranchesUpdate = branches => {
    this.props.update({
      uuid: this.props.item.uuid,
      hidden_branches: branches,
    });
  };

}