import React, { useEffect, useMemo, useRef } from "react";
import "./SelectionMenu.scss";
import { classnames } from "../../../../../../utilities/classnames";

function SelectionMenu(props) {
  const {options = [], hidden = [], onItemChange} = props;
  const selectAllCheckRef = useRef();
  const selected = useMemo(() => {
    return options.map(option => option.id.toString()).filter(id => !hidden.includes(id));
  }, [options, hidden]);

  useEffect(() => {
    selectAllCheckRef.current.checked = selected.length === options.length;
    selectAllCheckRef.current.indeterminate = selected.length > 0 && selected.length < options.length;
  }, [hidden, selected, options]);

  function onChange(id) {
    const updated = [...hidden];
    const idToAdd = id.toString();

    // exists already
    if (updated.includes(idToAdd)) {
      const idx = updated.findIndex(x => x === idToAdd);
      updated.splice(idx, 1);
    } else {
      updated.push(idToAdd);// add the branch to that item
    }

    onItemChange(updated); // Update the data on the line items
  }

  const clearAll = () => onItemChange(options.map(x => x.id.toString()));
  const selectAll = () => onItemChange([]);
  const toggleSelectAll = (e) => e.target.checked ? selectAll() : clearAll();

  return (
    <div className="selection-menu">
      <span className="section-title">Available at</span>
      <ul>
        <li className={"selection-menu-option"}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                type="checkbox"
                onChange={(e) => toggleSelectAll(e)}
                ref={selectAllCheckRef}
                id={`select-all`}
              />
              <label htmlFor={`select-all`} className={"text-muted small"}>{selected.length} locations selected</label>
            </div>
            <div>
              {selected.length > 0 && (<a className={"small"} onClick={clearAll}>Clear</a>)}
            </div>
          </div>

        </li>
      </ul>
      <div className="divider horizontal my-2"></div>
      <ul>
        {
          options.map(option => {
            let checked = selected.includes(option.id.toString());
            return (
              <li className={classnames({"selection-menu-option": true, "checked": checked})} key={option.id}>
                <input id={`location-${option.name}`} type="checkbox" checked={checked} onChange={() => onChange(option.id)}/>
                <label htmlFor={`location-${option.name}`}>{option.name}</label>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

export default SelectionMenu; 