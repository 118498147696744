import React from 'react';
import { axios } from "../../utilities/axios";
import { Link } from 'react-router-dom';
import AuthScene from './AuthScene/AuthScene';

class NewPasswordScene extends React.Component {

  state = {loading: false, password: '', password_confirmation: '', error: null, success: false};

  resetToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('reset_password_token');
  }

  render() {
    const resetToken = this.resetToken();

    const {loading, password, password_confirmation, error, message, success} = this.state;

    return <AuthScene title={"New Password"}>
      {resetToken == null
        ? <p className="error">Token invalid. Please click a valid link from the password reset email you were sent</p>
        : success
          ?
          <React.Fragment>
            <p className="success">Password successfully reset. Please login with your new password</p>
            <Link to="/" className={"button"}>Back to Login</Link>
          </React.Fragment>
          :
          <React.Fragment>
            <p>
              Please enter your new password below.
            </p>

            {error ? <p className="error">{error}</p> : null}

            <form onSubmit={this.onSubmit.bind(this)} className={loading ? 'loading' : ''}>
              <input name="password" type="password" placeholder="Password" value={password || ""} onChange={this.onChange.bind(this)}/>
              <input name="password_confirmation" type="password" placeholder="Password Confirmation" value={password_confirmation || ""} onChange={this.onChange.bind(this)}/>
              {message ? <p className="message">{message}</p> : null}
              <button className={loading ? 'loading' : ''} onClick={this.onSubmit.bind(this)}>{loading ? 'Loading...' : 'Set Password'}</button>
            </form>
          </React.Fragment>
      }
    </AuthScene>;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({error: false, success: false, loading: true});
    const prefix = "";
    const resetToken = this.resetToken();

    axios.post(
      `${prefix}/api/business/v1/authentication/new-password`,
      {
        password_reset_token: resetToken,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      },
    )
      .then(res => this.setState({loading: false, success: true}))
      .catch(err => this.setState({loading: false, error: err.response.data['error'] || 'Something went wrong'}));
  }

  onChange(i) {
    this.setState({[i.target.name]: i.target.value});
  }

}

export default NewPasswordScene;
  
