import React from 'react';
import { axios } from "../../utilities/axios";
import { connect } from 'react-redux';
import AuthScene from "./AuthScene";
import { Link } from "react-router-dom";

class ResetPasswordScene extends React.Component {

  state = {loading: false, email: '', error: null};

  render() {
    const {loading, email, error, message} = this.state;

    return <AuthScene title={"Forgotten Password"}>
      {error ? <p className="error">{error}</p> : null}

      <p className={"text-center"}>
        Enter the email address you use to log in.
        <br/>
        If an account exists with that email address, you will receive an email with a link to reset your password.
      </p>

      <form onSubmit={this.onSubmit.bind(this)} className={loading ? 'loading' : ''}>
        <input name="email" type="email" placeholder="Email Address" value={email} onChange={this.onChange.bind(this)}/>
        {message ? <p className="message">{message}</p> : null}
        <button className={loading ? 'loading' : ''} onClick={this.onSubmit.bind(this)}>{loading ? 'Loading...' : 'Reset Password'}</button>
        <br/>
        <Link to="/">Back to Login</Link>
      </form>
    </AuthScene>;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({error: false, loading: true});
    const prefix = "";

    axios.post(`${prefix}/api/business/v1/authentication/reset-password`, {email: this.state.email})
      .then(res => this.setState({loading: false, message: "If the given email address is registered, you will receive an email with instructions on how to reset your password."}))
      .catch(err => this.setState({loading: false, error: 'Something went wrong'}));
  }

  onChange(i) {
    this.setState({[i.target.name]: i.target.value});
  }

}

const mapStateToProps = store => {
  return {
    // loadingFromCookies: store.auth.loadingFromCookies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setUser: payload => dispatch({type: 'AUTH_SET_USER', payload}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScene);
  
